<template>
    <div>
        <!-- Search bar -->
        <b-row align-h="center">
            <b-col>
                <b-card no-body>
                    <b-card-header class="pb-50">
                        <h5>Filters</h5>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <!-- <b-col cols="1">
                                <label></label>
                                <h5>Filters</h5>
                            </b-col> -->
                            <b-col>
                                <label>Start and End Dates</label>
                                <flat-pickr
                                    v-model="searchItemDateRange"
                                    class="form-control"
                                    :config="{ mode: 'range' }"
                                    name="searchItemDateRange"
                                />
                            </b-col>
                            <b-col>
                                <label>Status</label>
                                <v-select
                                    v-model="searchItemStatus"
                                    :options="statusType"
                                    label="title"
                                />
                            </b-col>

                            <b-col cols="12" md="3" class="mb-md-0 mb-2">
                                <label>First Name</label>
                                <div
                                    class="
                                        d-flex
                                        align-items-center
                                        justify-content-end
                                    "
                                >
                                    <b-input-group>
                                        <b-form-input
                                            v-model="searchItemName"
                                            placeholder="eg: John"
                                        />
                                        <!-- <b-input-group-append>
                                        <b-button @click="searchByName()" variant="primary">
                                        <feather-icon
                                            icon="SearchIcon"
                                            size="14"
                                        />
                                        </b-button>
                                    </b-input-group-append> -->
                                    </b-input-group>
                                </div>
                            </b-col>
                            <b-col>
                                <!-- needed for alignment with input boxes on the side -->
                                <label></label>
                                <div v-if="$can('read', 'MiniApp')">
                                    <b-button
                                        @click="searchQuery()"
                                        variant="primary"
                                    >
                                        Search</b-button
                                    >
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-card no-body v-show="!showNoUsers">
            <onboarding-table :data="userInfo" @changePageNumber="onChangePageNumber" />
        </b-card>
        <!-- Start Loading and stop when backend query has ended -->
        <div class="loading-table-container" v-show="loadingUsers">
            <b-spinner
                style="width: 3rem; height: 3rem"
                label="Loading"
                variant="primary"
            ></b-spinner>
        </div>
        <b-card v-show="showNoUsers">
            <b-card-title>No Applications Found</b-card-title>
            <b-card-text
                >There are no users requesting our services today.</b-card-text
            >
        </b-card>
    </div>
</template>
<script>
import * as dayjs from "dayjs";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import OnboardingTable from "./OnboardList.vue";
import API from "./api";

export default {
    components: {
        OnboardingTable,
        vSelect,
        flatPickr,
    },
    data() {
        return {
            userInfo: {
                list: [],
                total: 0,
                totalPages: 0,
                hasMore: false,
            },
            showNoUsers: false,
            loadingUsers: true,
            dayjs: dayjs,
            // searchQueryStatus: false, // To determine if I am currently searching the db or not
            // searchResultsNotFound: false,
            searchItemStatus: null,
            searchItemName: null,
            searchItemDateRange: null,
            statusType: [
                {
                    title: "Pending",
                    code: 1,
                },
                {
                    title: "Approved",
                    code: 5,
                },
                {
                    title: "Rejected",
                    code: -1,
                },
                {
                    title: "Banned",
                    code: -5,
                },
            ], // 1, normal, 100 featured(can appear on home page), 101 featured(can appear on search suggestions)
        };
    },
    mounted() {
        this.getOnboarders();
    },
    watch: {
        searchItemName: function (params) {
            if (!params) {
                this.getOnboarders({}, 1, 200);
            }
        },
        searchItemStatus: function (params) {
            // console.log(params)
            // console.log(JSON.stringify(params.code, undefined, 2));

            if (!params) {
                // console.log(JSON.stringify(params.code, undefined, 2));
                this.getOnboarders({}, 1, 200);
            }
        },
        searchItemDateRange: function (params) {
            // console.log(params)
            if (!params) {
                this.getOnboarders({}, 1, 200);
            }
        },
    },
    methods: {
        async getOnboarders(searchParams = {}, pageNum = 1, resetParam) {
            const { data, success } = await API.getList({
                page: pageNum,
                pageSize: 10,
                searchParams: searchParams,
            });
            if (success) {
                let dataSet;

                if (this.userInfo.list.length > 0 && resetParam !== 200) {
                    dataSet = this.userInfo.list.concat(data.list);
                    // if (this.searchItemStatus || this.searchItemName || this.searchItemDateRange) {
                    //     dataSet = data.list;
                    // }
                } else {
                    dataSet = data.list;

                    if (dataSet.length === 0) {
                        this.showNoUsers = true;
                        this.loadingUsers = false;
                    }
                }

                const { total } = data;
                const { totalPages } = data;
                const { hasMore } = data;

                this.userInfo.list = dataSet;
                this.userInfo.total = total;
                this.userInfo.totalPages = totalPages;
                this.userInfo.hasMore = hasMore;

                this.loadingUsers = false;
                this.paddRows(pageNum);
            }
        },
        searchQuery() {
            // console.log("This is called")
            var params = {};

            if (this.searchItemName) {
                params.firstName = this.searchItemName;
            }

            if (this.searchItemDateRange) {
                params.startDate = this.searchItemDateRange
                    .split("to")[0]
                    .trim();
                params.endDate = this.searchItemDateRange.split("to")[1].trim();
            }

            if (this.searchItemStatus) {
                params.status = this.searchItemStatus.code;
            }

            this.getOnboarders(params, 1, 200);
        },
        paddRows(params) {
            const rowsDispenser = Math.max(
                0,
                params * 10 - this.userInfo.total
            );
            // console.log(rowsDispenser);

            for (let index = 0; index < rowsDispenser; index++) {
                this.userInfo.list.push({ isBlank: true });
                // console.log(JSON.stringify(this.userInfo.result, undefined, 2))
            }
        },
        onChangePageNumber(params) {
            this.getOnboarders({}, params, 0);
        },
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
