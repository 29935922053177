<template>
    <div>
        <b-table
            id="onboadingTable"
            responsive
            striped
            head-variant="light"
            head-row-variant="primary"
            :items="data.list"
            :fields="tableLabels"
        >
            <template #head(clientId)="data">
                <span class="text-info">{{ data.label.toUpperCase() }}</span>
            </template>
            <!-- Client code -->
            <template #cell(clientId)="data">
                
                <div
                    v-if="!data.item.isBlank"
                    class="d-flex align-items-center"
                >
                    <b-link
                        class="text-monospace"
                        :to="{
                            name: 'apps-miniapp-view',
                            params: { id: data.item.clientId },
                        }"
                    >
                        <span>{{ data.item.clientId }}</span>
                    </b-link>
                </div>
            </template>

            <!-- Client -->
            <template #head(firstName)="data">
                <span class="text-info">{{ data.label.toUpperCase() }}</span>
            </template>
            <template #cell(firstName)="data">
                <div
                    v-if="!data.item.isBlank"
                    class="d-flex align-items-center"
                >
                    <div>
                        <div
                            class="font-weight-bolder"
                            style="font-size: larger"
                        >
                            <span>{{ data.item.firstName }} </span>
                            <span>{{ data.item.lastName }} </span>
                        </div>
                        <div class="font-small-2 text-muted" v-if="data.item.dob">
                            Born on
                            {{ dayjs(data.item.dob).format("DD/MM/YYYY") }}
                        </div>
                    </div>
                </div>
                <div v-else class="d-flex align-items-center">
                    <!-- <span class="data-not-available">na</span> -->
                </div>
            </template>

            <!-- Product -->
            <template #head(serviceType)="data">
                <span class="text-info">{{ data.label.toUpperCase() }}</span>
            </template>
            <template #cell(serviceType)="data">
                <div
                    v-if="!data.item.isBlank"
                    class="d-flex align-items-center"
                >
                    <div>
                        <span v-if="data.item.serviceType === 1"
                            >Camtel Sim</span
                        >
                        <span v-if="data.item.serviceType === 5"
                            >Optical Fibre</span
                        >
                    </div>
                </div>
                <div v-else class="d-flex align-items-center">
                    <!-- <span class="data-not-available">na</span> -->
                </div>
            </template>

            <!-- Usage -->
            <template #head(userType)="data">
                <span class="text-info">{{ data.label.toUpperCase() }}</span>
            </template>
            <template #cell(userType)="data">
                <div
                    v-if="!data.item.isBlank"
                    class="d-flex align-items-center"
                >
                    <div>
                        <span v-if="data.item.userType === 1">Personal</span>
                        <span v-if="data.item.userType === 5">Enterprise</span>
                    </div>
                </div>
                <div v-else class="d-flex align-items-center">
                    <!-- <span class="data-not-available">na</span> -->
                </div>
            </template>

            <!-- Contact Number -->
            <template #head(phone)="data">
                <span class="text-info">{{ data.label.toUpperCase() }}</span>
            </template>
            <template #cell(phone)="data">
                <div
                    v-if="!data.item.isBlank"
                    class="d-flex align-items-center"
                >
                    <div>
                        <span>{{ data.item.phone }}</span>
                    </div>
                </div>
                <div v-else class="d-flex align-items-center">
                    <!-- <span class="data-not-available">na</span> -->
                </div>
            </template>

            <!-- Date created -->
            <template #head(createdAt)="data">
                <span class="text-info">{{ data.label.toUpperCase() }}</span>
            </template>
            <template #cell(createdAt)="data">
                <div
                    v-if="!data.item.isBlank"
                    class="d-flex align-items-center"
                >
                    <div>
                        <span>{{
                            dayjs(data.item.createdAt).format(
                                "DD/MM/YYYY-hh:mm:ss"
                            )
                        }}</span>
                    </div>
                </div>
                <div v-else class="d-flex align-items-center">
                    <!-- <span class="data-not-available">na</span> -->
                </div>
            </template>

            <!-- Documents uploaded -->
            <template #head(images)="data">
                <span class="text-info">{{ data.label.toUpperCase() }}</span>
            </template>
            <template #cell(images)="data">
                <div
                    v-if="!data.item.isBlank"
                    class="d-flex align-items-center"
                >
                    <span v-for="(image, index) in data.item.images" :key="index" class="mr-1">
                        <b-img center rounded height="40" :src="image" @click="displayImage(image, data.item.images)" />
                    </span>
                </div>
                <div v-else class="d-flex align-items-center">
                    <!-- <span class="data-not-available">na</span> -->
                </div>
            </template>

            <!-- Status (Review) -->
            <template #head(status)="data">
                <span class="text-info">{{ data.label.toUpperCase() }}</span>
            </template>
            <template #cell(status)="data">
                <div
                    v-if="!data.item.isBlank"
                    class="d-flex align-items-center"
                >
                    <b-badge
                        v-if="data.item.status === 1"
                        variant="light-info"
                        >Pending</b-badge
                    >
                    <b-badge
                        v-if="data.item.status === 5"
                        variant="light-success"
                        >Approved</b-badge
                    >
                    <b-badge
                        v-if="data.item.status === -1"
                        variant="light-warning"
                        >Rejected</b-badge
                    >
                    <!-- <b-badge v-if="data.item.status === -2" variant="light-info"
                        >ID card Expired</b-badge
                    > -->
                    <b-badge
                        v-if="data.item.status === -5"
                        variant="light-danger"
                        >Banned</b-badge
                    >
                </div>
                <div v-else class="d-flex align-items-center">
                    <!-- <span class="data-not-available">na</span> -->
                </div>
            </template>
            <template #head(action)="data">
                <span class="text-info">{{ data.label.toUpperCase() }}</span>
            </template>
            <template #cell(action)="data">
                <div v-if="!data.item.isBlank">
                    <!-- <b-button
                        v-if="data.item.status === 1"
                        :to="{
                            name: 'apps-users-onboarding-review',
                            params: { id: data.item._id },
                        }"
                        >Review</b-button
                    > -->
                    <div
                        class="d-flex align-items-center justify-content-center"
                    >
                        <b-dropdown
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                        >
                            <template v-slot:button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="text-body align-middle mr-25"
                                />
                            </template>
                            <b-dropdown-item
                                v-if="data.item.status === 1"
                                :to="{
                                    name: 'apps-users-onboarding-review',
                                    params: { idMode: {id: data.item._id, mode: 'review'} },
                                }"
                            >
                                <feather-icon icon="Edit2Icon" class="mr-50" />
                                <span>Review</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-else :to="{
                                    name: 'apps-users-onboarding-review',
                                    params: { idMode: {id: data.item._id, mode: 'preview'} },
                                }">
                                <feather-icon icon="EyeIcon" class="mr-50" />
                                <span>View</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div v-else class="d-flex align-items-center">
                    <!-- <span class="data-not-available">na</span> -->
                </div>
            </template>
        </b-table>
        <div class="display-pagination-wrapper">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="reviewsTable"
                @change="loadNewPage"
            ></b-pagination>
        </div>
        <b-modal
            v-model="imageModal"
            centered
            title="Fullscreen image"
            ok-only
            ok-title="Okay"
            size="lg"
        >
            <b-card-text>
                <b-carousel
                    id="carouselDocuments"
                    v-model="slideDocuments"
                    controls
                    indicators
                    no-wrap
                    background="#ababab"
                    :interval="15000"
                >
                    <b-carousel-slide v-for="(item, index) in selectedImg" :key="index">
                        <template #img>
                            <b-img
                                center
                                width="auto"
                                height="380"
                                :src="item"
                                alt="image slot"
                            />
                        </template>
                    </b-carousel-slide>
                </b-carousel>
                <!-- <b-img center :src="selectedImg" fluid thumbnail rounded /> -->
            </b-card-text>
        </b-modal>
    </div>
</template>

<script>
import { ref, reactive, defineComponent } from "@vue/composition-api";
import * as dayjs from "dayjs";

export default defineComponent({
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    setup(data, { emit }) {
        const tableLabels = reactive([
            {
                key: "firstName",
                label: "Client",
            },
            {
                key: "serviceType",
                label: "Service",
            },
            {
                key: "userType",
                label: "Usage",
            },
            {
                key: "phone",
                label: "Phone Number",
            },
            {
                key: "images",
                label: "Documents",
            },
            {
                key: "createdAt",
                label: "Created on",
            },
            {
                key: "createdAt",
                label: "Created",
            },
            {
                key: "status",
                label: "Status",
                sortable: true,
            },
            "Action",
        ]);

        const perPage = ref(10);
        const currentPage = ref(1);
        const imageModal = ref(false);
        const selectedImg = ref([]);

        const slideDocuments = ref(0)
        const loadNewPage = pageNum => {
            if (data.hasMore) {
                // this.$parent.getAllReviews({}, pageNum);
                emit("changePageNumber", pageNum);

                // console.log('Emitter is through')
            }
        };

        const displayImage = (value, params) => {
            imageModal.value = true;
            selectedImg.value = params;
            let imgIndex = selectedImg.value.indexOf(value);
            slideDocuments.value = imgIndex;
        }

        return {
            tableLabels,
            perPage,
            currentPage,
            selectedImg,
            loadNewPage,
            imageModal,
            displayImage,
            slideDocuments,
            dayjs
        };
    },

    computed: {
        rows() {
            return this.data.total;
        },
    },
});
</script>
